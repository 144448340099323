import React, { Fragment } from "react"
import {
	Grid,
	Box,
	Typography,
	makeStyles,
	Fade,
	Divider,
} from "@material-ui/core/"
import loadable from "@loadable/component"
import Seo from "../components/Seo"
import { useTranslation } from "react-i18next"
const ContentRow = loadable(() => import("../components/ContentRow"))
const PopularCalcs = loadable(() => import("../components/PopularCalcs"))
const AddCar = loadable(() =>
	import("../components/mainsteps/transportsteps/AddCar")
)
const CarbonCompareTransport = loadable(() =>
	import("../components/CarbonCompareTransport")
)

const useStyles = makeStyles((theme) => ({
	bgImage: {
		backgroundImage:
			"url('https://cms.footprintforest.nl/wp-content/uploads/2021/01/cars.jpg')",
	},
}))

const CalculateCar = ({
	handleInput,
	handleRemoveClick,
	handleAddClick,
	inputList,
}) => {
	const classes = useStyles()

	const { t } = useTranslation()
	return (
		<Fade in={true} {...(true ? { timeout: 1000 } : {})}>
			<Grid container noValidate alignItems="stretch">
				<Seo
					title={t("seo.car.title")}
					description={t("seo.car.description")}
					pathSlug={t("/calculate-your-car")}
					keywords=""
				/>

				<Grid
					className={classes.bgImage + " bg-image-container"}
					item
					xs={12}
					sm={12}
				>
					<Box className="boxContainer large stretch">
						<Grid item xs={12} sm={12}>
							<Typography variant="h1" align="center">
								{t("calculatecar.title")}
							</Typography>
							<Typography variant="h3" component="h2" align="center">
								{t("calculateflights.subtitle")}
							</Typography>
							<Typography
								variant="body1"
								align="center"
								style={{ marginTop: 10, marginBottom: 10 }}
							>
								{t("calculatecar.introtext")}
							</Typography>
						</Grid>

						<Box>
							<AddCar
								handleInput={handleInput}
								handleRemoveClick={handleRemoveClick}
								handleAddClick={handleAddClick}
								inputList={inputList}
							/>
						</Box>

						<CarbonCompareTransport
							type="transport"
							distance={inputList.car[0].distance * 52}
							emission={inputList.car[0].emission}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<ContentRow
						maintitle={t("calculatecar.maintitle")}
						maincontent={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.main", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						block1title={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.reducetitle", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						block1content={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.reducecontent", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						block2title={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.howtitle", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						block2intro={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.howintro", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						meth1content={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.how1", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						meth2content={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.how2", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						meth3content={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.how3", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
						meth4content={
							<span
								dangerouslySetInnerHTML={{
									__html: t("calculatecar.how4", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						}
					/>
				</Grid>
				<Grid container>
					<Divider color="primary" />
				</Grid>

				<Grid container direction="row" justify="center" alignItems="stretch">
					<Grid item xs={12} sm={12} md={8}>
						<Box mb={80}>
							<PopularCalcs alt={true} />
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Fade>
	)
}

export default CalculateCar
