import React from "react";
import { Box, Typography, Grid, Fade, makeStyles } from "@material-ui/core/";
import footerImage from "../img/forestfootprint-footer.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundImage: "url('" + footerImage + "')",
  },
}));
const Footer = (position, timeout) => {
  const classes = useStyles();
  return (
    <Fade in={true} {...(true ? { timeout: timeout } : {})}>
      <Box className={classes.footer}>
        <Grid container className={position.position + " " + classes.footer}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography
                variant="h3"
                component="h1"
                align="center"
              ></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default Footer;
