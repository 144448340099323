import React, { Component } from "react"
import {
	Grid,
	Box,
	Typography,
	Button,
	Fade,
	makeStyles,
} from "@material-ui/core/"
import Seo from "../components/Seo"
import { Trans, useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({}))

const About = () => {
	const classes = useStyles()
	const [t] = useTranslation("translations")

	return (
		<Fade in={true} {...(true ? { timeout: 1000 } : {})}>
			<Grid container noValidate alignItems="stretch">
				<Seo
					title={t("seo.aboutus.title")}
					description={t("seo.aboutus.description")}
					pathSlug={t("/about-us")}
					keywords=""
				/>
				<Grid item xs={12} sm={12}>
					<Box className="boxContainer large">
						<Grid item xs={12} sm={12}>
							<h2>{t("aboutus.title")}</h2>

							<Typography variant="body1" color="initial">
								{t("aboutus.intro")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<h2>{t("aboutus.block1title")}</h2>

							<Typography
								variant="body1"
								color="initial"
								dangerouslySetInnerHTML={{
									__html: t("aboutus.block1content", {
										interpolation: { escapeValue: false },
									}),
								}}
							></Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<h2>{t("aboutus.block2title")}</h2>

							<Typography
								variant="body1"
								color="initial"
								dangerouslySetInnerHTML={{
									__html: t("aboutus.block2content", {
										interpolation: { escapeValue: false },
									}),
								}}
							></Typography>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Fade>
	)
}

export default About
